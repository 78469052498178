import { FC, memo, useMemo } from 'react';

import { InfoOutlined as InfoIcon, Search as DetailIcon } from '@mui/icons-material';
import { Card, Tooltip } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useLocation } from 'react-router-dom';

import ECarePhoneCall from 'api/eCarePhoneCall/eCarePhoneCall';
import {
  EcarePhoneCall,
  EcarePhoneCallListFilters,
  eCarePhoneCallStatusDictionary,
  eCarePhoneCallCallTypeDictionary,
  PhoneCallStatus,
} from 'api/eCarePhoneCall/eCarePhoneCall.types';
import { ReactComponent as PhoneCallIcon } from 'assets/icons/hub/active/phone_call.svg';
import PageTitleWithFiltersForTables from 'components/UI/molecules/PageTitleWithFiltersForTables/PageTitleWithFiltersForTables';
import CustomTable from 'components/UI/organisms/_tables/CustomTable/CustomTable';
import { AvailableFilter } from 'constants/_types/AvailableFilter';
import DATE_FORMATS from 'constants/dates/DATE_FORMATS';
import QUERY_KEYS from 'constants/queryKeys/queryKeys';
import PATHS from 'constants/router/PATHS';
import generatePolishPostCode from 'helpers/generatePolishPostCode/generatePolishPostCode';
import getAgeFromDOB from 'helpers/getAgeFromDOB/getAgeFromDOB';
import useTableData from 'hooks/useTableData/useTableData';
import exhaustiveGuard from 'services/exhaustiveGuard/exhaustiveGuard';
import theme from 'styles/theme';
import generalMessages from 'translations/common/general.mjs';
import ecarePhoneCallMessages from 'translations/specific/ecarePhoneCall.mjs';

import useStyles from './EcarePhoneCallsList.styles';

const EcarePhoneCallsList: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const statusesList = [
    { label: t(ecarePhoneCallMessages.status.answered), value: 'ODEBRANE' },
    { label: t(ecarePhoneCallMessages.status.unanswered), value: 'NIEODEBRANE' },
    { label: t(ecarePhoneCallMessages.status.abandoned), value: 'PORZUCONE' },
    { label: t(ecarePhoneCallMessages.status.finished), value: 'WYKONANE' },
  ];

  const callTypeList = [
    { label: t(ecarePhoneCallMessages.callType.incoming), value: 'PRZYCHODZACE' },
    { label: t(ecarePhoneCallMessages.callType.outgoing), value: 'WYCHODZACE' },
  ];

  const { query, sort, onSort, page, onFilter, onExactPage, filters, pagesCount } = useTableData<keyof EcarePhoneCall>({
    queryKeyBase: QUERY_KEYS.GET_ECARE_PHONE_CALL,
    queryFunction: ECarePhoneCall.getEcarePhoneCallsList(),
  });

  const { data } = query;

  const columnHelper = createColumnHelper<EcarePhoneCall>();
  const columns = [
    columnHelper.accessor('internalId', {
      header: t(ecarePhoneCallMessages.table.id) as string,
      id: 'internal_id',
      cell: info => info.getValue(),
      meta: {
        sx: { maxWidth: '8ch' },
      },
    }),
    columnHelper.accessor('status', {
      header: t(ecarePhoneCallMessages.table.status) as string,
      id: 'status',
      cell: info => {
        const value = info.getValue();
        if (value === null) return t(generalMessages.noData);
        return t(eCarePhoneCallStatusDictionary[value]);
      },
    }),
    columnHelper.accessor('subscription', {
      header: t(ecarePhoneCallMessages.table.subscription) as string,
      id: 'subscription',
      cell: info => info.getValue() || t(generalMessages.noData),
    }),
    columnHelper.accessor('callDate', {
      header: t(ecarePhoneCallMessages.table.callDate) as string,
      id: 'call_date',
      cell: info => format(info.getValue(), DATE_FORMATS.DISPLAY_TIME),
      meta: {
        sx: { width: '20ch' },
      },
    }),
    columnHelper.accessor('patient.personalData.firstName', {
      header: t(ecarePhoneCallMessages.table.firstName) as string,
      id: 'service_recipient__patient__personal_data__first_name',
      cell: info => info.getValue() || t(generalMessages.noData),
    }),
    columnHelper.accessor('patient.personalData.lastName', {
      header: t(ecarePhoneCallMessages.table.lastName) as string,
      id: 'service_recipient__patient__personal_data__last_name',
      cell: info => info.getValue() || t(generalMessages.noData),
    }),
    columnHelper.accessor('patient.pesel', {
      header: t(ecarePhoneCallMessages.table.pesel) as string,
      id: 'service_recipient__patient__pesel',
      cell: info =>
        info.row.original.patient
          ? `${info.getValue()}, ${getAgeFromDOB(info.row.original.patient.personalData.dateOfBirth)}l`
          : t(generalMessages.noData),
      meta: {
        sx: { width: '20ch' },
      },
    }),
    columnHelper.accessor('patient.projectUserNumber', {
      header: t(ecarePhoneCallMessages.table.projectUserNumber) as string,
      id: 'service_recipient__patient__project_user_number',
      cell: info => info.getValue() || t(generalMessages.noData),
      meta: {
        sx: { width: '4ch' },
      },
    }),
    columnHelper.accessor('patient.personalData.mainAddress.city', {
      header: t(ecarePhoneCallMessages.table.city) as string,
      id: 'service_recipient__patient__personal_data__main_address__city',
      cell: info => info.getValue() || t(generalMessages.noData),
    }),
    columnHelper.accessor('patient.personalData.mainAddress.postcode', {
      header: t(ecarePhoneCallMessages.table.postalCode) as string,
      id: 'service_recipient__patient__personal_data__main_address__postcode',
      cell: info => (info.getValue() ? generatePolishPostCode(info.getValue()) : t(generalMessages.noData)),
      meta: {
        sx: { width: '4ch' },
      },
    }),
    columnHelper.accessor('patient.personalData.mainAddress', {
      header: t(ecarePhoneCallMessages.table.street) as string,
      id: 'service_recipient__patient__personal_data__main_address__street_name',
      cell: info => {
        if (!info.getValue()) return t(generalMessages.noData);
        const { streetName, buildingNumber, apartmentNumber } = info.getValue();

        const address = apartmentNumber ? `${streetName} ${buildingNumber} / ${apartmentNumber}` : `{street} ${buildingNumber}`;

        return address;
      },
    }),
    columnHelper.accessor('callType', {
      header: t(ecarePhoneCallMessages.table.callType) as string,
      id: 'call_type',
      cell: info => t(eCarePhoneCallCallTypeDictionary[info.getValue()]),
      meta: {
        sx: { maxWidth: '20ch' },
      },
    }),
    columnHelper.accessor('payer', {
      header: t(ecarePhoneCallMessages.table.payer) as string,
      id: 'payer',
      cell: info => info.getValue() || t(generalMessages.noData),
      meta: {
        sx: { width: '15ch' },
      },
    }),
    columnHelper.accessor('operator', {
      header: memo(() => (
        <>
          {t(ecarePhoneCallMessages.table.carer)}
          <Tooltip enterDelay={750} title={t(ecarePhoneCallMessages.bitrix.operator) as string}>
            <InfoIcon sx={{ width: '15px', marginLeft: theme.spacing(0.5) }} />
          </Tooltip>
        </>
      )),
      id: 'operator__last_name',
      cell: info => {
        if (!info.getValue()) return t(generalMessages.noData);
        const { first_name, last_name } = info.getValue();
        return `${first_name} ${last_name}`;
      },
    }),
    columnHelper.display({
      id: 'detailsView',
      enableSorting: false,
      cell: memo((info: any) => (
        <Link to={`/${generatePath(PATHS.PROFESSIONAL_ECARE_PHONE_CALL_SINGLE, { phoneCallId: `${info.row.original.id}` })}`}>
          <DetailIcon />
        </Link>
      )),
      meta: {
        sx: { maxWidth: '5ch' },
      },
    }),
  ];

  const location = useLocation() as { state: { filterBy?: { key: string; value: string } } };

  const availableFilters: AvailableFilter<EcarePhoneCallListFilters>[] = useMemo(
    () => [
      {
        key: 'internal_id',
        type: 'string',
        label: t(ecarePhoneCallMessages.filters.id),
        initialValue: location.state?.filterBy?.value,
      },
      { key: 'status', type: 'dropdown', label: t(ecarePhoneCallMessages.filters.status), options: statusesList },
      { key: 'subscription', type: 'string', label: t(ecarePhoneCallMessages.filters.subscription) },
      {
        key: 'call_date',
        type: 'dateRange',
        label: t(ecarePhoneCallMessages.filters.callDate),
        text: {
          start: t(generalMessages.form.dateFrom),
          end: t(generalMessages.form.dateTo),
        },
        hasTooltip: true,
        tooltipText: t(ecarePhoneCallMessages.filters.callDate),
      },
      { key: 'service_recipient__patient__personal_data__first_name', type: 'string', label: t(ecarePhoneCallMessages.filters.firstName) },
      { key: 'service_recipient__patient__personal_data__last_name', type: 'string', label: t(ecarePhoneCallMessages.filters.lastName) },
      { key: 'service_recipient__patient__pesel', type: 'string', label: t(ecarePhoneCallMessages.filters.pesel) },
      {
        key: 'service_recipient__patient__project_user_number',
        type: 'string',
        label: t(ecarePhoneCallMessages.filters.projectUserNumber),
      },
      {
        key: 'service_recipient__patient__personal_data__main_address__city',
        type: 'string',
        label: t(ecarePhoneCallMessages.filters.city),
      },
      {
        key: 'service_recipient__patient__personal_data__main_address__postcode',
        type: 'string',
        label: t(ecarePhoneCallMessages.filters.postalCode),
      },
      {
        key: 'service_recipient__patient__personal_data__main_address__street_name',
        type: 'string',
        label: t(ecarePhoneCallMessages.filters.address),
      },
      { key: 'call_type', type: 'dropdown', label: t(ecarePhoneCallMessages.filters.callType), options: callTypeList },
      { key: 'payer', type: 'string', label: t(ecarePhoneCallMessages.filters.payer) },
      { key: 'operator__last_name', type: 'string', label: t(ecarePhoneCallMessages.filters.carer) },
    ],
    [t],
  );

  const pageTitle = {
    headerLabel: t(ecarePhoneCallMessages.title),
    icon: <PhoneCallIcon />,
    itemsCount: data?.count || 0,
  };

  const customFilter = {
    availableFilters,
    filters,
    onFilter,
  };

  const rowClassResolver = (status: PhoneCallStatus) => {
    switch (status) {
      case 'WYKONANE':
        return classes.completeColor;
      case 'ODEBRANE':
        return classes.receivedColor;
      case 'PORZUCONE':
        return classes.dropColor;
      case 'NIEODEBRANE':
        return classes.missedColor;
      case null:
        return classes.noDataColor;
      default:
        return exhaustiveGuard(status);
    }
  };

  return (
    <div>
      <PageTitleWithFiltersForTables customFilters={customFilter} pageTitle={pageTitle} />
      <Card>
        {/* Generic Component throws error, but idk why, it should work */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <CustomTable<EcarePhoneCall>
          columns={columns}
          data={data?.data}
          page={page}
          pagesCount={pagesCount}
          rowClassResolver={(rowValues: PhoneCallStatus) => rowClassResolver(rowValues.status)}
          sort={sort}
          onExactPage={onExactPage}
          onSort={onSort}
        />
      </Card>
    </div>
  );
};

export default EcarePhoneCallsList;
